import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import Seo from '../components/seo'
import ContentContainer from '../components/UI/ContentContainer'
import Layout from '../layouts/layout'
import AppStoreLogo from '../assets/images/svg/app-store-logo.svg'
import GooglePlayLogo from '../assets/images/svg/google-play-logo.svg'

const AppDownload = ({ path }) => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": "Get the SocietyOne App",
    "datePublished": "2022-03-12",
  }
  return (
    <Layout>
      <Seo title="Download App" path={path} structuredData={structuredData} />
      <ContentContainer>
        <main>
          <AppDownloadWrapper>
            <div className="col heroImg">
              <StaticImage
                src="../assets/images/app-download.png"
                alt="apple-pay-hero-image"
                placeholder="none"
              />
            </div>
            <h1>Get the SocietyOne App</h1>
            <p>
              Open your account in minutes and receive your digital debit card
              instantly so you can start spending today.
            </p>

            <div className="flex-row">
              <a
                className="logo"
                rel="noopener noreferrer"
                target="_blank"
                href="https://apps.apple.com/au/app/societyone/id1532681841"
              >
                <AppStoreLogo className="svg" />
              </a>
              <a
                style={{ marginLeft: 20 }}
                className="logo"
                rel="noopener noreferrer"
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.societyone.app"
              >
                <GooglePlayLogo className="svg" />
              </a>
            </div>
          </AppDownloadWrapper>
        </main>
      </ContentContainer>
    </Layout>
  )
}

const AppDownloadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    margin-bottom: 24px;
    max-width: 680px;
  }
  p {
    font-size: 18px;
    margin: 0;
    max-width: 680px;

    text-align: center;
  }
  .heroImg {
    width: 100%;
    max-width: 320px;
  }
  .col {
    margin-bottom: 40px;
  }
  .flex-row {
    padding-top: 60px;
  }
  .logo {
    height: 50px;
    width: auto;
  }
  .svg {
    height: 100%;
    width: auto;
  }
`

export default AppDownload
